import { Form } from "antd";
import { useRef, useState, useEffect, DependencyList } from "react";
import type { ProFormInstance } from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-components";

export const useProForm = () => {
    const formRef = useRef<ProFormInstance>();
    return {
        form: () => formRef.current,
        reset: () => formRef.current?.resetFields(),
        submit: () => formRef.current?.submit(),
        props: {
            formRef,
        },
    };
};

export const useForm = () => {
    const [form] = Form.useForm();
    return {
        form,
    };
};

export const useModalForm = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const ref = useRef({ row: undefined });

    const open = (data: any = undefined) => {
        setVisible(true);
        if (!data) {
            form.resetFields();
        } else {
            //need to reset fields for make ProFormTreeSelect do request again when reopen the modalForm
            form.resetFields();
            form.setFieldsValue(data);
        }
        ref.current.row = data;
    };

    const getData = (): any => {
        return ref.current.row;
    };

    return {
        open,
        getData,
        form,
        props: {
            visible,
            onVisibleChange: setVisible,
            form,
        },
    };
};

export const useDraw = () => {
    const [_open, setOpen] = useState(false);
    const ref = useRef<any>({ data: {} });
    const [dep, setDep] = useState(0);

    const open = (data: any = {}) => {
        ref.current.data = data;
        setOpen(true);
        setDep(dep + 1);
    };

    const close = () => {
        setOpen(false);
    };

    const useDrawEffect = (effect: () => void, deps: DependencyList = []) => {
        useEffect(() => {
            if (_open) {
                return effect();
            }
        }, [dep, ...deps]);
    };

    return {
        open,
        close,
        getData: () => ref.current.data,
        useDrawEffect,
        dep,
        props: {
            open: _open,
            onClose: () => setOpen(false),
            destroyOnClose: true,
        },
    };
};

export const useDrawerForm = () => {
    const [form] = Form.useForm();
    const [_open, setOpen] = useState(false);
    const ref = useRef<any>({ data: {} });
    const [dep, setDep] = useState(0);

    const open = (data: any = {}) => {
        // console.log("data", data);
        ref.current.data = data;
        setOpen(true);
        setDep(dep + 1);
        if (!data) {
            form.resetFields();
        } else {
            //need to reset fields for make ProFormTreeSelect do request again when reopen the modalForm
            form.resetFields();
            form.setFieldsValue(data);
        }
    };
    const close = () => {
        setOpen(false);
    };

    const useDrawEffect = (effect: () => void, deps: DependencyList = []) => {
        useEffect(() => {
            if (_open) {
                return effect();
            }
        }, [dep, ...deps]);
    };

    return {
        open,
        close,
        getData: () => ref.current.data,
        useDrawEffect,
        dep,
        props: {
            width: 900,
            labelCol: { span: 6 },
            form,
            visible: _open,
            onVisibleChange: setOpen,
        },
    };
};

export const useProTable = () => {
    const actionRef = useRef<ActionType | undefined>();
    const formRef = useRef<ProFormInstance>();
    const reload = () => {
        actionRef.current?.reload();
    };

    return {
        form: () => formRef.current,
        action: () => actionRef.current,
        reload,
        props: {
            actionRef,
            formRef,
            scroll: { x: "max-content" },
            pagination: {
                defaultPageSize: 10,
                showSizeChanger: true,
            },
        },
    };
};

export const useModal = () => {
    const [_open, setOpen] = useState(false);
    const ref = useRef<any>({ data: {} });

    const open = (data: any = {}) => {
        setOpen(true);
        ref.current.data = data;
    };

    return {
        open,
        getData: () => ref.current.data,
        props: {
            open: _open,
            onCancel: () => setOpen(false),
        },
    };
};
